<template>
  <van-cell :title="text"></van-cell>
  <van-button round block type="primary" @click="this.show = true">测试</van-button>
  <QRReader
      v-model:show="show"
      @onRead="onRead"
  />
</template>
<script>


import QRReader from "@/components/QRReader.vue";

export default {
  name: "QRReaderTest",
  components: {
    QRReader
  },
  data() {
    // 二维码
    return {
      show: false,
      text: '空'
    }
  },
  methods:{
    onRead(text) {
      this.text = text;
    }
  }
}
</script>

<style scoped>

</style>